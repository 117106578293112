.header-all {
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
  margin-top: 0;
  line-height: 1.4;
  font-family: $typo-secondary;
}

.header-1 {
  font-size: $header-1;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 2.1875rem;
  }
}
.header-2 {
  font-size: $header-2;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.875rem;
  }
}
.header-3 {
  font-size: $header-3;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.header-4 {
  font-size: $header-4;
  @extend .header-all;
}
.header-5 {
  font-size: $header-5;
  @extend .header-all;
}
.header-6 {
  font-size: $header-6;
  @extend .header-all;
}

.page-body {
  p {
    font-size: $font-size;
  }
}

.titulos {
  margin-bottom: $space-3;
  @media screen and (max-width: 767px) {
    margin-bottom: $space-2;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: $space-1;
  }

  &__pretitulo {
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    color: $color-text-titles;
    text-transform: uppercase;
    margin-bottom: $space-05;
    &--light {
      color: $color-white;
    }
    @media screen and (max-width: 480px) {
      font-size: $font-size-smaller;
      font-weight: $font-weight-medium;
    }
  }
  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-2;
    font-weight: $font-weight-normal;
    color: $color-text-titles;
    @media screen and (max-width: 991px) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
      color: $color-primary;
    }

    &--light {
      color: $color-white;
    }
    margin-bottom: $space-05;

    span {
      font-style: italic;
    }
  }

  &__subtitulo {
    font-size: $display-4;
    font-weight: $font-weight-bold;
    color: $color-text-titles;
    &--sm {
      font-size: $header-3;
      font-weight: $font-weight-normal;
    }
    span {
      font-size: $header-3;
    }
  }

  &__descripcion {
    color: $color-grey-3;
    font-size: 1rem;
    font-weight: $font-weight-normal;
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
    p {
      margin-bottom: 0;
    }
    &--sm {
      font-size: $font-size-small;
    }
  }

  &--center {
    text-align: center;
    .titulos__titulo {
      text-align: center;
    }
  }
  &--50 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1281px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &--60 {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1281px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &--70 {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1281px) {
      width: 85%;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &--primary {
    color: $color-primary;
  }

  &--light {
    color: $color-white;
  }
  &--accent-light {
    color: $color-accent-light;
  }
  &--mb-0 {
    margin-bottom: 0;
  }
  &--mb-05 {
    margin-bottom: $space-05;
  }
  &--mb-1 {
    margin-bottom: $space-1;
  }
  &--mb-2 {
    margin-bottom: $space-2;
  }
}

.borderline--before {
  &::before {
    content: "";
    width: 150px;
    height: 6px;
    background-color: $color-primary-light;
    display: block;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  &--center::before {
    margin-right: auto;
    margin-left: auto;
  }
}

.borderline--after {
  &::after {
    content: "";
    width: 150px;
    height: 6px;
    background-color: $color-primary-light;
    display: block;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }
  &--sm::after {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  &--center::after {
    margin-right: auto;
    margin-left: auto;
  }
}

.greyline--before::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
  display: block;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.greyline--after::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
  display: block;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
