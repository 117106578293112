.card-block {
  margin-bottom: $space-2;
  padding: 3rem;
  border-radius: $border-radius;
  background-color: $color-white;
  @media screen and (max-width: 575px) {
    padding: 1.2rem;
    margin-bottom: $space-2;
  }

  &--shadow {
    box-shadow: $box-shadow-small;
  }

  &--dashed {
    background-color: inherit;
    border: dashed 2px $color-secondary;
  }

  &--pad-mini {
    padding: 0.5rem;
  }

  &--pad-min {
    padding: 1.5rem;
    @media screen and (max-width: 767px) {
      padding: $font-size-smaller;
    }
  }

  &--reset {
    background-color: inherit !important;
    box-shadow: none;
    padding: 0px;
  }

  &__content {
    margin-bottom: 2rem;
    font-size: $font-size-small;
  }

  &__item {
  }

  &__list {
  }
  &__list-item {
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 0.3rem;
    &::before {
      position: absolute;
      left: 0;
      top: 3px;
      display: block;
      content: "";
      background: url("../assets/ico/ico-check.svg") no-repeat;
      width: 16px;
      height: 16px;
    }
  }

  &__keys {
    .bi {
      margin-right: 0.65rem;
    }
  }

  &__title {
    border-bottom: 1px solid rgb(233, 233, 233);
    margin-bottom: $space-1;
    h4 {
      color: $color-secondary;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      font-size: $font-size-big;
    }
  }

  &__subtitle {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    h5 {
      font-size: $font-size-small;
    }
  }

  &__body {
    font-size: $font-size;
    &--min {
      @media screen and (max-width: 767px) {
        font-size: $font-size-small;
      }
    }
  }
}

.mini-card {
  background-color: $color-background-2;
  display: flex;

  gap: 1rem;
  column-gap: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: $border-radius;
  width: auto;
  max-width: 280px;
  @media screen and (max-width: 767px) {
    padding: 0.5rem 0.5rem;
  }
  &--100 {
    width: 100%;
  }
  &__picture {
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    line-height: 1.2;
  }
  &__titulo {
    color: $color-primary;
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
    font-size: $font-size-smaller;
  }
  &__descripcion {
    font-size: $font-size-small;
  }
}

.ico {
  display: inline-flex;
  border-radius: 50px;
  position: relative;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    position: absolute;
    display: block;
    content: "";
    border-radius: 50px;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border: 1px dashed $color-primary;
  }
  &--min {
    width: 4rem;
    height: 4rem;
    &::before {
      top: 5px !important;
      bottom: 5px !important;
      left: 5px !important;
      right: 5px !important;
    }
    img {
      max-height: 2rem;
      width: 100px;
      height: auto;
    }
  }
}

.ico--green {
  background-color: #b2ded4;
}
