.g-blog {
  &__terms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: $space-2;
    gap: $font-size-smaller;
    overflow: hidden;
    .active {
      background-color: $color-primary-dark !important;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: auto;
      padding-bottom: $font-size;
      margin-bottom: $space-1;
      margin-left: -1.1rem;
      margin-right: -0.7rem;
      .boton {
        white-space: nowrap;
        &:first-child {
          margin-left: 1.1rem;
        }
        &:last-child {
          margin-right: 1.1rem;
        }
      }
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: $space-2;
    }
  }
}

.thumb-blog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $color-text-body;
  &:hover {
    color: $color-text-body;
  }
  &__thumbnail {
    margin-bottom: $font-size;
    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__titulo {
    font-size: $header-3;
    color: $color-dark;
    a {
      font-family: $typo-secondary;
    }
  }

  &__categoria {
    margin-bottom: 0.5rem;
    a {
      color: $color-dark;
      font-size: $font-size-smaller;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
    }
  }
  &__excerpt {
    color: $color-grey-3;
  }
}

.entrada {
  &__container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 70px 1fr 70px;
    gap: $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: 40px 1fr;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content {
  }

  .post__cat {
    margin-top: 0;
  }
}

.share-post {
  position: sticky;
  top: $space-5;
  margin-top: $font-size-smaller;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;
    align-items: center;
  }
  &__item {
    margin-bottom: $font-size;
    @media screen and (max-width: 991px) {
      margin-right: 0.75rem;
      margin-bottom: 0;
    }
  }

  svg {
    width: 35px;
    height: auto;
    path:first-child {
      fill: $color-grey-4 !important;
    }
  }
}

.recetas {
  display: flex;
  flex-direction: column;
  gap: $space-2;
  margin-top: $space-2;
  @media screen and (max-width: 767px) {
    gap: $space-1;
  }
  &__card {
    padding: $space-2;
    background-color: $color-background-2;
    border-radius: $border-radius;
    border: 1px dashed $color-primary-light;
    @media screen and (max-width: 767px) {
      padding: $space-1;
    }
  }
  &__titulo {
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      color: $color-primary;
      margin-bottom: $font-size;
    }
  }
  &__items {
  }
  &__item {
    // padding-left: $font-size-bigger;
    position: relative;
    display: flex;
    font-size: $font-size-small;
    &--accion {
      padding-top: $font-size-smaller;
      padding-bottom: $font-size-smaller;
      border-bottom: 1px dashed $color-grey-4;
    }
    &::before {
      flex: 0 0 $font-size-bigger;
      content: "-";
      font-weight: bold;
      color: $color-primary-light;
      display: block;
      // position: absolute;
      // left: 0;
      // top: 0px;
    }
  }
}
