.bloque-3col {
  .ff-stream {
    padding: 0 !important;
    min-height: auto !important;
  }

  .ff-stream-wrapper {
    margin: 0 !important;
    padding: 0 !important;

    .ff-item-header {
      display: none !important;
    }
  }

  .ff-loadmore-wrapper {
    visibility: hidden !important;
    display: none;
  }

  // .ff-slide-wrapper {
  //   // max-height: 700px !important;
  // }
}

.actitud {
  &__content {
    padding-bottom: $space-3;
    text-align: center;
    font-size: $font-size-big;
  }
  .ff-stream {
    padding: 0 !important;
    min-height: 100% !important;
    background-color: transparent !important;
  }
  .ff-stream-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  .ff-item {
    box-shadow: $box-shadow;
  }
  .ff-loadmore-wrapper {
    visibility: hidden !important;
    display: none;
  }
}

.ff-slideshow .ff-supports-comments .ff-comments-list {
  display: none !important;
}

.footer__item--flow {
  .ff-stream {
    padding: 0 !important;
    min-height: 100% !important;
    background-color: transparent !important;
  }
  .ff-stream-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  // .ff-item {
  //   box-shadow: $box-shadow;
  // }

  .ff-item-cont {
    min-height: 93px !important;
  }
  .ff-item .ff-img-holder {
    min-height: 93px !important;
  }

  .ff-loadmore-wrapper {
    visibility: hidden !important;
    display: none;
  }
  .ff-item-bar {
    display: none;
  }
  .ff-carousel-icon {
    // width: 24px;
    // height: 24px;
    // background-image: url("../../dist/assets/ico/image.svg");
    // margin-top: 3px;
    // margin-right: 3px;
    display: none;
  }
}

#ff-stream-2 .ff-overlay {
  background-color: rgba(0, 0, 0, 0.92) !important;
}
