.cuadro {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: $color-background-2;
  padding: $space-2 $space-1;
  gap: $font-size / 2;
  border-radius: $font-size;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(6, 1fr);
    gap: $font-size ($font-size / 2);
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(3, 1fr);
  }
  &__mes {
  }

  &__titulo {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 0.7rem;
    color: $color-primary;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.2rem;
    }
  }

  &__floracion {
    height: 1.8rem;
    background-color: rgba($color: #000000, $alpha: 0.1);
    background-color: $color-white;
    border: 1px solid darken($color-background-2, $amount: 5);
    border-radius: 50px;
    &--active {
      border: 1px solid $color-secondary;
      background-image: url("../assets/ico/ico-flower-2.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-color: $color-secondary;
      background-size: 16px;
    }
  }
}
