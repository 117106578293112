.distribuidores {
  // display: grid;
  //grid-template-columns: repeat(2, minmax(0, 1fr));
  //grid-template-columns: 30% 70%;
  //gap: 0;
  display: flex;
  flex-direction: column;

  &__lista {
    display: none;
  }

  &__grid {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    // gap: 3rem;
    display: flex;
    flex-direction: column;
  }

  &__mapa {
    //padding-left: 2rem;
    width: 100%;
    .facetwp-facet {
      margin-bottom: 0;
    }
  }
}

.thumb-distribuidores {
  display: flex;
  //flex-direction: column;
  // display: grid;
  // grid-template-columns: 35% 65%;
  align-items: center;
  gap: 0;
  background-color: $color-white !important;
  box-shadow: $box-shadow-small;
  transition: $trans;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: 0.75rem;
  @media screen and (max-width: 575px) {
    box-shadow: $box-shadow-small;
  }

  &:hover {
    transition: $trans;
    translate: -3px -3px;
  }
  &__img {
    img {
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__content {
    padding: 0.875rem 2rem;
  }

  &__nombre {
    margin-bottom: 0;
    font-size: $header-4;
  }
}

.facetwp-facet-mapa_distribuidores {
  #facetwp-map {
    width: 100%;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border: 1px dashed $color-grey-3;
  }
  .facetwp-map-filtering {
    display: none;
  }
  .gm-style .gm-style-iw-c {
    max-width: 300px !important;
    max-height: 300px !important;
  }
  .fmap__thumbnail img {
    width: 100%;
    height: auto;
  }
}

.distribuidor {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
  }

  &__left {
  }

  &__img {
    img {
      aspect-ratio: 16/12;
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: 0.75rem;
    }
  }

  &__right {
    padding-left: 3rem;
  }

  &__colecciones {
  }

  &__mapa {
    margin-top: 3rem;
  }
}

.acf-map {
  width: 100%;
  height: 400px;
  border-radius: $border-radius;
  box-shadow: $box-shadow-small;
  margin-bottom: $space-2;
  @media screen and (max-width: 767px) {
    height: 500px;
  }
  &--locations {
    height: 50vh;
  }
}

.marker {
  &__content {
    display: flex;
    gap: $font-size;
  }
  &__thumb {
    width: 170px;
    @media screen and (max-width: 767px) {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__logo {
    img {
      width: 140px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 100px;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-size: $header-4;
    @media screen and (max-width: 767px) {
      font-size: $header-5;
    }
  }
  &__adress {
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size / 3;
    }
  }
}

.contacto {
  &__content {
    color: $color-grey-3;
  }

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 8rem;
    &--form {
      grid-template-columns: 1fr 2fr;
      margin-bottom: 6rem;
      .contacto__col1 {
        @media screen and (max-width: 991px) {
          margin-bottom: 3rem;
        }
      }
    }
    &--1col {
    }
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    &--presupuesto {
      display: flex;
      justify-content: center;
      .contacto__col2 {
        padding-left: 0;
        width: 60%;
      }
    }
  }

  &__col1 {
  }

  &__mapa {
    iframe {
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }

  &__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    color: $color-grey-3;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__direccion {
    margin-bottom: 0.6875rem;
    line-height: 1.5;
  }

  &__descripcion {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    font-size: $font-size;
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    .bi {
      margin-right: 0.5rem;
    }
    &:nth-last-child(1) {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  &__telefonos-telefono {
    color: $color-grey-3;
    text-decoration: none;
    &:hover {
      color: $color-grey-2;
    }
  }

  &__horario {
    font-size: $font-size-small;
    strong {
      color: $color-secondary-dark;
    }
    &--inline {
      display: flex;
      p:nth-child(1) {
        margin-right: 1.5rem;
      }
    }
  }
}

.mapa {
  iframe {
    width: 100%;
  }
}

.page-template-page-presupuestos .contacto__descripcion {
  margin-bottom: 0;
  p {
    margin-bottom: 0;
  }
}
