.menu-principal {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  li.menu-item {
    a {
      padding: 0.3125rem 1.5625rem;
      text-decoration: none;
      color: $color-white;
      font-size: $font-size-small;
      text-transform: uppercase;
      @media screen and (min-width: 768px) and (max-width: 1010px) {
        padding: 0.3125rem 0.65rem;
      }
      @media screen and (min-width: 1011px) and (max-width: 1199px) {
        padding: 0.3125rem 0.7rem;
      }
      &:hover,
      &:active {
        color: $color-grey-5;
      }
    }
    &.current_page_item a {
      color: $color-grey-5;
    }
  }
  &--left {
    margin-right: auto;
  }
  &--right {
  }
}

.header--sticky {
  li.menu-item {
    a {
      color: $color-dark;
    }
  }
}

.ubermenu-main {
  @media screen and (max-width: 1200px) {
    display: none !important;
    transition: $trans;
  }
}

.ubermenu-loc-primary_left {
  margin-right: auto !important;
}

.ubermenu {
  .ubermenu-item {
    .ubermenu-target {
      text-decoration: none;
      color: $color-white !important;
      transition: $trans;
      // text-transform: uppercase;
      font-size: $font-size-small;
      &:hover {
        color: $color-grey-5 !important;
        transition: $trans;
      }
      @media screen and (max-width: 1440px) {
        padding: 15px 10px;
      }
      @media screen and (max-width: 1280px) {
        padding: 15px 8px;
      }
    }
  }
  .ubermenu-submenu {
    background-color: $color-background !important;
  }
}

.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  //width: 100% !important;
  width: max-content !important;
  background-color: rgba($color-black, $alpha: 0.9) !important;
  position: absolute !important;
  top: 100% !important;
  margin-top: 0px !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  overflow: hidden;
  left: auto !important;
  display: flex !important;
  flex-direction: column !important;
  .ubermenu-target {
    color: $color-dark !important;
    color: $color-white !important;
    transition: $trans;

    &:hover {
      background-color: $color-primary-light !important;
      color: $color-background !important;
      transition: $trans;
    }
  }
  .ubermenu-target-text {
    // text-transform: uppercase !important;
  }
}

#offcanvasMenu {
  width: 100vw;
  border: none;
  .offcanvas-body {
    background-color: $color-background-2;
    button {
      display: flex;
      margin-left: auto;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;
      //padding-right: 2.65rem;
      padding-top: 1rem;
      text-align: right;
      i {
        color: $color-primary;
        transition: $trans;

        &:hover {
          color: $color-white;
          transition: $trans;
        }
      }
      @media screen and (max-width: 480px) {
        padding-top: 0;
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    //padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    & > li.menu-item {
      margin-bottom: 0.7rem;
      & > a {
        font-size: $header-2-res;
        font-weight: $font-weight-medium;
        text-decoration: none;
        color: $color-primary;
        margin-bottom: 0.5rem;
        transition: $trans;

        &:hover {
          color: $color-primary-dark;
          transition: $trans;
        }
        @media screen and (max-width: 480px) {
          font-size: $header-4;
        }
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 5px;
          a {
            // background-color: $color-accent;
            // color: $color-background !important;
            font-size: 1rem;
            border-radius: 4px;
            display: inline-block;
            font-weight: 400;
            &:hover {
              // background-color: $color-accent-dark !important;
            }
          }
        }
      }
    }
  }

  &__rs {
    display: flex;
    justify-content: center;
    .zoom-social-icons-list {
      margin-left: -10px !important;
    }
    .social-icon {
      font-size: 32px !important;
      background-color: $color-primary !important;
      color: $color-background-2 !important;
      transition: $trans;
      @media screen and (max-width: 767px) {
        font-size: 20px !important;
        padding: 5px !important;
      }
      &:hover {
        background-color: $color-primary-dark !important;
        color: $color-background-2 !important;
        transition: $trans;
      }
    }
  }
  &__logo {
    display: flex;
    width: 240px;
    margin: 2rem auto 0 auto;
    @media screen and (max-width: 480px) {
      margin-top: 0.5rem;
    }
  }
}

#offcanvasSearch {
  background-color: $color-background;
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    button {
      position: absolute;
      top: 2rem;
      right: 2rem;
      display: flex;
      margin-left: auto;
      margin-bottom: auto;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;
      //padding-right: 2.65rem;
      padding-top: 1rem;
      text-align: right;
      i {
        color: $color-primary;
        transition: $trans;
        &:hover {
          color: $color-primary-light;
          transition: $trans;
        }
      }
      @media screen and (max-width: 480px) {
        padding-top: 0;
      }
    }
  }
}

.buscador {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center; //margin-left: auto;

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &__form {
    width: 90%;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  &__wrap {
    width: 100%;
  }
  &__input {
    width: 100%;
    font-size: 6em;
    background-color: $color-background;
    border: 0;
    border-bottom: 1px solid $color-primary-light;
    color: $color-secondary;
    &:visited {
      color: $color-secondary;
      text-decoration: none;
    }
    &::placeholder {
      color: $color-secondary;
      opacity: 1;
    }
    &:focus-visible {
      outline: none;
    }
  }

  &__img {
    width: 20px;
    height: auto;
  }
}
