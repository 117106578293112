.header {
  position: fixed !important;
  width: 100%;
  padding: 1.25rem 2rem;
  z-index: 11;
  padding: 1.4375rem 2rem;
  background-color: transparent;
  transition: $trans;
  @media screen and (max-width: 1400px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: $trans;
  }
  @media screen and (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: $trans;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 1090px) {
      justify-content: space-between;
    }
  }

  &--sticky {
    padding-top: $font-size;
    padding-bottom: $font-size;
    background-color: $color-white;
    box-shadow: $box-shadow-header;
    transition: $trans;
    @media screen and (max-width: 767px) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .header__logo {
      svg {
        width: 180px;
        height: auto;
        transition: $trans;
        @media screen and (max-width: 767px) {
          width: 140px;
        }
      }
    }
    .ubermenu .ubermenu-item .ubermenu-target {
      color: $color-dark !important;
      transition: $trans;
    }
    .header__burger i,
    .header__search i {
      color: $color-dark;
      transition: $trans;
    }

    .header__logo svg path {
      fill: $color-primary;
      transition: $trans;
    }

    @media screen and (max-width: 767px) {
      padding-top: $space-05;
      padding-bottom: $space-05;
    }
  }

  &__logo {
    margin-right: $space-2;
    transition: $trans;

    @media screen and (max-width: 1400px) {
      margin-right: $font-size;
    }
    @media screen and (max-width: 1400px) {
      margin-right: 0;
    }
    img,
    svg {
      width: 210px;
      height: auto;
      transition: $trans;

      @media screen and (max-width: 1400px) {
        width: 180px;
      }
      @media screen and (max-width: 767px) {
        width: 180px;
      }
      path {
        fill: $color-white;
        transition: $trans;
      }
    }
  }

  &__nav {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin-left: 0;
    }
    @media screen and (max-width: 1200px) {
      display: none;
      transition: $trans;
    }
    &--left {
      margin-right: auto;
    }
  }

  &__search {
    margin-right: 5px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    i {
      color: $color-white;
      cursor: pointer;
      &:hover {
        color: color-grey-5;
      }
    }
    @media screen and (max-width: 1400px) {
      padding-right: 0.5rem;
      padding-left: 0.875rem;
      transition: $trans;
    }
    @media screen and (max-width: 1200px) {
      margin-left: auto;
      transition: $trans;
      i {
        font-size: 1.4rem;
        transition: $trans;
      }
    }
  }

  &__burger {
    margin-left: 1rem;
    button {
      border: none;
      background: none;
    }
    i {
      color: $color-white;
      font-size: 2.5rem;
      @media screen and (max-width: 767px) {
        font-size: $header-3;
      }
    }
    @media screen and (min-width: 1201px) {
      display: none;
    }
  }
}
