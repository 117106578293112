html {
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;
}

a {
  color: $color-primary;
  text-decoration: none;
  font-family: $typo-primary;
  transition: $trans;
  &:hover {
    text-decoration: none;
    transition: $trans;
    color: $color-primary-dark;
  }
}

.d-block {
  width: 100%;
}

.menu-visible {
  overflow-y: hidden;
}
