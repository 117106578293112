.feat {
  &__item {
    display: flex;
    margin-bottom: $space-4;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-2;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1.5fr 1fr;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 3rem;
    }
    &--inverted {
      @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1.5fr;
      }

      .feat__card-picture {
        order: 2;
        @media screen and (max-width: 991px) {
          order: 1;
        }
      }
      .feat__card-content {
        order: 1;
        padding: 0 4rem 0 0;
        @media screen and (max-width: 991px) {
          padding: 1.5rem 0;
          order: 2;
          @media screen and (max-width: 767px) {
            padding: 0.2rem 0;
          }
        }
      }
    }
  }

  &__card-picture {
    position: relative;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }

    img {
      position: relative;
      aspect-ratio: 16 / 10;
      width: 100%;
      height: auto;
      object-fit: cover;
      //@include img-round;
      @media screen and (max-width: 1280px) {
        aspect-ratio: 16 / 12;
      }
      @media screen and (max-width: 991px) {
        aspect-ratio: 16 / 11;
      }
      @media screen and (max-width: 575px) {
      }
    }
  }

  &__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 4rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0.2rem 0;
    }
    &--center {
      align-items: center;
      text-align: center;
    }
  }

  &__card-pretitle {
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: $font-weight-medium;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 1199px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }

  &__card-title {
    font-family: $typo-secondary;
    font-size: $header-2;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    color: $color-dark;
    margin-bottom: $space-01;
    a {
      font-family: $typo-secondary;
      font-size: $header-2;
      font-weight: $font-weight-bold;
      line-height: 1.4;
      color: $color-dark;
      letter-spacing: -1px;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 1440px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  &__card-description {
    p {
      font-size: $font-size;
      line-height: 1.5;
      color: $color-grey-3;
      @media screen and (max-width: 991px) {
        font-size: $font-size-small;
      }
    }
  }
}
