.stitle {
  padding: $space-4 0;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    gap: $space-5;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      gap: $space-1;
    }
  }

  &__header {
    @media screen and (min-width: 992px) {
      flex: 0 0 30%;
    }
  }

  &__title {
    color: $color-primary;
    font-size: $header-2;
    font-family: $typo-secondary;
  }

  &__description {
  }
}

.page-title {
  z-index: 1;
  background-color: $color-primary;
  padding: $space-6 0 $space-4;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-bottom: $space-2;
  }
  &__cover {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent
      linear-gradient(
        69deg,
        rgba($color: $color-primary, $alpha: 0.9) 0%,
        rgba($color: $color-primary-light, $alpha: 0.5) 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.83;
  }

  &__container {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
    @media screen and (min-width: 1401px) {
      height: 450px;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-end;
      height: calc(100vh - 66px);
      height: 250px;
    }
  }

  &__h {
    color: $color-white;
    font-family: $typo-secondary;
    // font-weight: $font-weight-bold;
    font-size: $display-2;
    line-height: 1;
    @media screen and (max-width: 1399px) {
      font-size: $display-3;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
      line-height: 1.3;
    }
  }
  .page-breadcrumb {
    position: relative;
  }

  &--singular {
    padding: 0;
    .page-title__container {
      height: 94px;
      @media screen and (max-width: 767px) {
        height: 54px;
      }
    }
  }

  &--blog {
    .page-title__container {
      //height: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__tiempo {
    text-align: center;
    display: flex;
    color: $color-white;
    i {
      margin-right: $font-size-smaller / 2;
    }
  }

  &__meta {
    margin-top: $space-1;
    font-size: $font-size-small;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space-1;
  }

  &__categoria {
    color: $color-white;
    a {
      color: $color-white;
      text-transform: uppercase;
    }
  }
}

.page-breadcrumbs {
  font-size: $font-size-smaller;
  color: $color-white;
  margin-bottom: $font-size-small;
  a {
    color: $color-white;
    text-decoration: none;
    font-weight: $font-weight-medium;
    &:hover {
      color: $color-grey-5;
    }
  }
  &--dark {
    color: $color-grey-3;
    a {
      color: $color-primary;
      &:hover {
        color: $color-primary-dark;
      }
    }
  }
}
