.hero {
  position: relative;
  overflow: hidden;

  &__video {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent linear-gradient(69deg, #000000 0%, #54545400 100%)
      0% 0% no-repeat padding-box;
    opacity: 0.83;
  }

  &__container {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__content {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 85%;
    padding-top: $space-6 + $space-4;
    padding-bottom: $space-6;
    color: $color-white;

    @media screen and (max-width: 991px) {
      width: 90%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      padding-top: $space-6;
      padding-bottom: $space-1;
      min-height: 60vh;
      justify-content: flex-end;
    }
  }

  &__pretitulo {
    font-size: $font-size-bigger;
    font-weight: $font-weight-bold;
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      font-size: 1.15rem;
    }
  }

  &__titulo {
    font-family: $typo-secondary;
    font-size: $display-2;
    font-weight: $font-weight-normal;
    line-height: 1.2;
    margin-bottom: 2rem;
    @media screen and (max-width: 1281px) {
      font-size: 3.5rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2.3rem;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: $space-1;
      font-size: $display-3;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.9rem;
    }
  }

  &__description {
    font-weight: $font-weight-medium;
    margin-bottom: 3rem;
  }

  &__actions {
    .boton--accent {
      margin-right: 1rem;
      @media screen and (max-width: 575px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;
    padding: 2rem;
  }
}
