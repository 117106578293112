.caracteristicas {
  background-color: $color-background-2;
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  &__cover {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: $space-2;
    @media screen and (max-width: 1399px) {
      font-size: $font-size-small;
    }
  }

  &__titulo {
    font-family: $typo-secondary;
    color: $color-primary;
    margin-bottom: $space-1;
    font-size: $header-3;
    @media screen and (max-width: 1399px) {
      margin-bottom: $font-size;
    }
    a {
      font-family: $typo-secondary;
    }
  }
}

.empresa {
  background-color: $color-grey-6;

  &__content {
    max-width: 75%;
    @media screen and (max-width: 1280px) {
      max-width: 90%;
    }
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  &__pretitulo {
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-dark;
    font-weight: $font-weight-bold;
    margin-bottom: 0.6875rem;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }

  &__titulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    color: $color-dark;
    letter-spacing: -1px;
    a {
      color: $color-dark;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 1440px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }

  &__descripcion {
    p {
      font-size: $font-size-bigger;
      line-height: 2.2;
      color: $color-grey-3;
      @media screen and (max-width: 991px) {
        font-size: $font-size-big;
      }
    }
  }
  &__logos {
    padding-top: $space-2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__logo {
    margin-right: 3rem;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    &:nth-child(2) {
      img {
        height: 120px;
        width: auto;
      }
    }
    img {
      height: 50px;
      width: auto;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 2rem;
    }
  }
}
