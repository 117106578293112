.footer {
  background-color: $color-secondary-dark;
  box-shadow: 0 50vh 0 50vh $color-secondary-dark;
  padding: $space-3 0 $space-3 0;
  @media screen and (max-width: 767px) {
    padding: 4rem 0 2rem 0;
  }
  &__container {
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 3rem;
    justify-content: center;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .footer__item--logo {
        order: 3;
      }
      .footer__item--menu-info {
        order: 1;
      }
      .footer__item--menu-legal {
        order: 2;
      }
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0;
      align-items: center;
      text-align: center;
      .footer__item--logo {
        order: 1;
      }
      .footer__item--menu-info {
        order: 2;
      }
      .footer__item--menu-legal {
        order: 3;
      }
    }
  }

  &__item {
    color: white;
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }

  &__item--logo {
    img {
      width: 132px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 120px;
      }
      @media screen and (max-width: 480px) {
        width: 90px;
      }
    }
  }

  &__item--contacto {
    font-size: $font-size-small;
  }

  &__direccion {
    margin-bottom: 0.5rem;
  }

  &__item--rs {
    .widget-rs {
      .zoom-social-icons-list {
      }
      .zoom-social_icons-list__item {
        margin: 0 !important;
      }
      .social-icon {
        font-size: 20px !important;
        color: $color-grey-3 !important;
        background-color: $color-white !important;
        @media screen and (max-width: 767px) {
          font-size: 16px !important;
        }
        &:hover {
          color: $color-grey-5 !important;
        }
      }
    }
  }

  &__item--certs {
    margin-top: $space-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-1;
  }
  &__item--cert {
    img {
      // width: 100%;
      // min-width: 55px;
      // min-height: 60px;
    }
  }

  &__item--cert-global {
    img {
      width: 70px;
      aspect-ratio: 7/6;
      height: auto;
    }
  }

  &__item--cert-grasp {
    img {
      width: 45px;
      aspect-ratio: 4/6;
      height: auto;
    }
  }

  &__item--cert-acpo {
    img {
      width: 125px;
      aspect-ratio: 17/6;
      height: auto;
    }
  }

  // &__item--imagenes {
  //   margin-top: $space-1;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   gap: $space-1;
  //   img {
  //     height: auto;
  //     min-width: 55px;
  //     min-height: 60px;
  //   }
  //   section:nth-child(1) img {
  //     aspect-ratio: 1;
  //     width: auto;
  //   }
  //   section:nth-child(2) img {
  //     aspect-ratio: 0.7;
  //     width: auto;
  //   }
  //   section:nth-child(3) img {
  //     aspect-ratio: 1.2;
  //     width: auto;
  //   }
  // }
}

.widget-footer {
  &__title {
    font-size: $font-size;
    font-weight: $font-weight-bold;
    color: $color-white;
    margin-bottom: $space-1;
  }
  a {
    color: $color-white;
    // font-size: $font-size-small;
    &:hover {
      color: $color-grey-5;
    }
  }
  ul.menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    .menu-item {
      a {
        padding: 10px 0;
      }
    }
  }
  p {
    font-size: $font-size-small;
    margin-bottom: 0;
  }
}

.copyright {
  background-color: $color-secondary-dark;
  padding: 0 0 $space-3 0;
  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 0 0 4rem 0;
  }

  &__container {
    border-top: 1px dashed $color-white;
    padding-top: $space-2;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      justify-content: center;
      gap: $font-size;
    }
  }

  &__copy {
    color: $color-white;
    font-size: $font-size-small;
  }

  &__navbar {
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  &__nav {
    list-style: none;
    padding-left: 0;
    display: flex;
    margin-bottom: 0;
    .menu-item::after {
      color: $color-white;
      content: " -\00a0";
    }
    .menu-item:nth-last-child(1)::after {
      content: "";
    }
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
    a {
      color: $color-white;
      font-size: $font-size-small;
      text-decoration: none;
      &:hover {
        color: $color-grey-5 !important;
      }
    }
  }
}
