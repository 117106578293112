@mixin button {
  padding: 1rem 1.625rem; // cambiar tamaño
  font-size: 1rem;
  border-radius: 50px; // cambiar radius
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  line-height: 1;
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 0.625rem 1.25em;
    font-size: $font-size-small;
  }
}

@mixin pad-l-r {
  padding-left: 1rem;
  padding-right: 1rem;
}

@mixin max-w-1700 {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}

@mixin tag-principal {
  font-size: 0.875rem;
  border-radius: 4px;
  background-color: $color-primary;
  color: $color-white !important;
  text-decoration: none;
  padding: 0.3rem 0.7rem;
  box-shadow: $box-shadow-small;
}

@mixin lista-tags {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  li {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    a {
      @include tag-principal;
    }
  }
}

@mixin lista-tags-vertical {
  padding-left: 0;
  list-style: none;
  display: block;
  li {
    margin-bottom: 0.5rem;
    a {
      @include tag-principal;
    }
  }
}

@mixin badge {
  border: none;
  border-radius: 3px;
  padding: 2px 6px;
  font-size: $font-size;
  line-height: 1.5em;
  font-weight: normal;
  background-color: $color-secondary;
  color: $color-white;
}
