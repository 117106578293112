.bloque-3col {
  &__content {
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-2;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: $space-2;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-text-body;
    &:hover {
      color: $color-text-body;
    }
    &--left {
      .bloque-3col__data {
        text-align: left;
        align-items: flex-start;
        padding: 0;
      }
    }
    // @media screen and (max-width: 991px) {
    //   display: grid;
    //   grid-template-columns: repeat(2, minmax(0, 1fr));
    //   gap: 0rem;
    //   margin-bottom: 2rem;
    // }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__cover {
    width: 100%;
    aspect-ratio: 1 / 1;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size;
    }
  }

  &__img {
    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 1rem 2rem 0rem;
    @media screen and (max-width: 991px) {
      align-items: flex-start;
      text-align: left;
    }
    @media screen and (max-width: 575px) {
      align-items: center;
      text-align: center;
      paddint-top: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  &__pretitulo {
    text-transform: uppercase;
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    margin-bottom: 0.5rem;
  }

  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-3;
    text-align: center;
    color: $color-primary;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
  }

  &__descripcion {
    color: $color-grey-3;
  }

  #sb_instagram {
    padding-bottom: 0 !important;
  }
  #sbi_images {
    padding: 0 !important;
  }
}

.destacado {
  background-color: $color-background-2;
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__left {
  }

  &__img {
    img {
      width: 100%;
      height: 580px;
      object-fit: cover;
      @media screen and (max-width: 991px) {
        height: 420px;
        margin-bottom: 1rem;
      }

      @media screen and (max-width: 575px) {
        height: 380px;
        margin-bottom: 1rem;
      }
      @media screen and (max-width: 480px) {
        height: 320px;
        margin-bottom: 1rem;
      }
    }
  }

  &__right {
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 767px) {
      padding: 0 0rem;
    }
  }

  &__pretitulo {
  }

  &__titulo {
  }

  &__descripcion {
  }

  &__actions {
    display: flex;
    justify-content: center;
    .boton--primary {
      margin-right: 1rem;
    }
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: center;
      .boton {
        width: 100%;
        &--primary {
          margin-right: 0;
          margin-bottom: 0.875rem;
        }
      }
    }
  }
}

.novedades {
  position: relative;
  &__col1 {
    padding: 0 3rem;
    position: absolute;
    width: 20%;
    top: 12rem;
  }

  &__boton {
  }

  &__col2 {
    position: relative;
    left: 20%;
    width: 80%;
  }

  &__slider {
    margin-bottom: $space-4;
  }

  &__item {
    padding: 0 1rem;
  }

  &__img {
    img {
      aspect-ratio: 4/5;
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: 0.75rem;
    }
  }

  &__titulo {
    font-family: $typo-secondary;
    font-size: $header-3;
    text-align: center;
    color: $color-dark;
  }
}
