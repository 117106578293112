.certificados {
  &__content {
    display: flex;
    flex-direction: column;
    gap: $space-2;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: $space-1;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $color-grey-4;
    margin-right: $space-2;
    @media screen and (max-width: 767px) {
      border: 0;
      justify-content: flex-start;
    }
    img {
      width: 60%;
      height: auto;
      @media screen and (max-width: 767px) {
        max-width: 200px;
        max-height: 80px;
        width: auto;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__titulo {
    font-size: $header-2;
    font-weight: bold;
    margin-bottom: $space-1;
    line-height: 1;
    color: $color-primary;
    font-family: $typo-secondary;
    letter-spacing: -1px;
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
    }
  }

  &__descripcion {
    font-size: $font-size;
    color: $color-grey-3;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
    p {
      &:nth-last-child(1) {
        margin-bottom: 0 !important;
      }
    }
  }
}
