.cta {
  padding: $space-4 0;
  &__cta {
    position: relative;
    overflow: hidden;
    padding: $space-4 $space-3;
    border-radius: $border-radius;
    overflow: hidden;
    @media screen and (max-width: 575px) {
      padding: $space-4 $space-1;
    }
  }

  &__img {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-primary-dark;
    opacity: 0.5;
  }

  &__container {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  &__content {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-white;
  }

  &__icono {
    width: 5.25rem;
    height: auto;
    margin-bottom: 1.25rem;
  }

  &__pretitulo {
    font-size: $header-5;
    text-transform: uppercase;
    margin-bottom: $font-size-big;
    font-weight: $font-weight-medium;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }

  &__titulo {
    font-size: $display-3;
    font-family: $typo-secondary;
    line-height: 1.2;
    margin-bottom: $space-2;
    @media screen and (min-width: 992px) {
      width: 80%;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: $space-1;
    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
    }
  }
}
