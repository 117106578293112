.wpcf7 {
  margin: 0;

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: 0.85rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
    display: block;
  }

  .wpcf7-not-valid {
    border: 1px solid red !important;
  }
}

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: $color-grey-3;
  color: rgba(0, 0, 0, 0.4);

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

body:not(.page-template-page-contacto),
body:not(.page-template-page-distribucion) {
  .grecaptcha-badge {
    display: none;
  }
}

.m-search {
  position: fixed;
  z-index: 199;
  display: none;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-background;
  justify-content: center;
  align-items: center;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  &__close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    i {
      font-size: 2.5rem;
      color: $color-primary-light;
      cursor: pointer;
      transition: $trans;
      &:hover {
        color: $color-primary;
        transition: $trans;
      }
    }
  }
}
