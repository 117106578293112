.g-plantas {
  background-color: $color-background-2;
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $space-4 $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      gap: $space-3 $space-1;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
      gap: $space-2;
    }
    &--2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.planta-thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__cover {
    margin-bottom: $font-size;
    position: relative;
    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
      //margin-bottom: 0.875rem;
    }
    // &:hover {
    //   transform: scale(1.1);
    //   box-shadow: $box-shadow;
    //   transition: $trans;
    // }
  }
  &__labels {
    position: absolute;
    bottom: $font-size;
    left: $font-size;
    right: $font-size;
    display: flex;
    //
    gap: $font-size;
    justify-content: center;
  }
  &__label {
    background-color: $color-accent;
    color: $color-white;
    padding: 0px $font-size-smaller;
    border-radius: 50px;
  }
  &__term-name {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: $typo-secondary;
    font-size: $header-3;
    color: $color-primary;
    margin-bottom: 0;
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-text-body;
    background-color: $color-background;
    &:hover {
      color: $color-text-body;
    }
  }

  &__thumbnail {
    position: relative;

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include badge;
  }

  &__coleccion {
    color: $color-grey-3;
    padding: 0 4rem;
  }
}

.search-respuesta {
  grid-column: 1 / span 3;
  &__vacio {
  }
}

.planta {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-1;
    }
  }

  &__cover {
    position: relative;
  }

  &__img {
    position: relative;
    img {
      aspect-ratio: 1/1;
      //aspect-ratio: 4/5;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__titulo {
    color: $color-primary;
    font-size: $header-1;
    font-family: $typo-secondary;
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
    }
  }

  &__coleccion {
    text-transform: uppercase;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
    }
  }

  &__botanic {
    font-family: $typo-primary;
    padding-left: $font-size-smaller;
    font-size: $header-4;
    color: $color-grey-4;
    img {
      margin-left: 2px;
      margin-right: 7px;
      width: 25px;
      height: auto;
      transform: translateY(-3px);
    }
  }

  &__badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include badge;
  }

  &__galeria {
    margin-top: 4px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 4px;
    .planta-galeria__img {
      aspect-ratio: 1 / 1;
      width: 100%;
    }
  }

  &__content {
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-2;
      p {
        font-size: $font-size-small;
      }
    }
  }
}

.relacionados {
  padding: $space-4 0 $space-5;
  background-color: $color-background-2;
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 4rem;
  }

  &__item {
  }

  &__img {
  }

  &__planta {
  }
}

.cuidados {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $font-size;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  // &__icono {
  //   padding: 0 2rem;
  //   width: auto;
  //   height: 5rem;
  //   margin-bottom: 1.5rem;
  //   path,
  //   line {
  //     stroke: $color-primary !important;
  //   }
  // }

  // &__info {
  // }

  // &__titulo {
  // }

  // &__descripcion {
  // }
}
